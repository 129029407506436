import React from 'react';

import CallToAction            from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading                 from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer                  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper                 from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import RandomBrandHeaderImages
                               from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import Display                 from '@interness/theme-default/src/components/Display/Display';
import LiveAnnouncements       from '@interness/web-core/src/components/structure/LiveAnnouncements/LiveAnnouncements';


function IndexPage(props) {
  return (
    <>
      <RandomBrandHeaderImages count={3}/>
      <Wrapper>
        <Spacer/>
        <section>
          <Heading subtitle={'Herzlich Willkommen'}>Juwelier Bernstein</Heading>
          <span style={{ textAlign: 'justify' }}>
            <p>Seien Sie herzlich willkommen bei Juwelier Bernstein in Marktheidenfeld, der schönsten Stadt in im unterfränkischen Landkreis Main-Spessart.</p>
            <p>Auf traditionelle Weise trägt Juwelier Bernstein seit vielen Jahren in Marktheidenfeld dazu bei, Ihre Wünsche und Träume bei Uhren, Schmuck und exklusiven Trauringen und Eheringen zu erfüllen.<br/>
            In unseren moderneren Geschäftsräumen, in der Luitpoldstraße 12 in Marktheidenfeld, möchten wir Ihnen ein wunderbares Einkaufserlebnis ermöglichen.<br/>
            Kompetente und persönliche Beratung rund um die Themen Schmuck, Uhren und bei Trauringen und Eheringen ist für das Team von Juwelier Bernstein selbstverständlich.<br/>
            Gerne widmen wir Ihnen unsere Aufmerksamkeit für Ihre individuellen Wünsche bei Uhren- Schmuck. Wir nehmen uns die Zeit für Sie, damit Sie Ihre ganz persönlichen Trauringe und Eheringe finden.</p>
            <p>Ihre Lieblinge sind bei unserem Uhrmachermeister in unserer Meisterwerkstatt gut aufgeboben. 
            Wir kaufen Ihr Altgold zu fairen und tagesaktuellen Preisen an.
            </p>  
            <p>Bis bald in Marktheidenfeld.<br/>Ihre Familie Bernstein</p>
          </span>
        </section>
        <LiveAnnouncements/>
        <Spacer/>
        <section>
          <Display/>
          <Spacer/>
        </section>
        <Spacer/>
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default IndexPage;
